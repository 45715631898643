.about {
    .name {
        margin: .5rem 0 0 1rem;
        font-size: 2rem;
        color: $white;
    }

    .you {
        height: 100%;

        a {
            color: $black;
            border-bottom: 1px solid color($nav-item-border-color, 'lighten-2');

            &:hover {
                border-bottom: 1px solid color($nav-item-border-color, 'base');
            }
        }

        .name {
            color: $black;
            text-align: center;
        }
    }

    .modal {
        .square {
            overflow: scroll;

            > .content {
                padding: 1.5rem 2rem;
            }
        }
    }

    .modal-cover .square {
        overflow: hidden;
    }

    .modal-content .square {
        overflow-x: hidden;
    }

    .philosophy {
        padding: 5rem 0 4rem;

        @media #{$small-and-down} {
            padding: 3rem 0 2rem;
        }

        .tabs {
            height: auto;
            background-color: transparent;

            .tab {
                height: auto;
                letter-spacing: normal;
                color: $black;
                text-transform: none;

                h5 {
                    @media #{$small-and-down} {
                        display: none;
                    }
                }

                a {
                    color: transparentize($black, .4);

                    &.active {
                        color: $black;
                    }
                }
            }
        }

        .tab-contents {
            h5 {
                display: none;

                @media #{$small-and-down} {
                    display: block;
                    margin: 2rem 0 -2rem;
                    text-align: center;
                }
            }
        }

        .indicator {
            height: 3px;
            background-color: $black;
        }
    }
}
