.map {
    height: 500px;
}

.send-message {
    span {
        display: inline-block;
        min-width: 50px;
        padding: 0 5px;
        background: $input-background;
        outline: none;

        &:focus {
            background: $input-background-focus;
        }
    }

    .btn,
    .btn-large {
        margin-top: 2rem;
    }
}

.contact-details {
    a {
        color: $black;
        border-bottom: 1px solid color($nav-item-border-color, 'lighten-2');
    }

    a:hover {
        border-bottom: 1px solid color($nav-item-border-color, 'base');

        &.fa-external-link {
            color: color('grey', 'darken-1');
        }
    }

    .active {
        border-bottom: 1px solid color($nav-item-border-color, 'darken-1');
    }

    address {
        margin-top: 15px;
        font-style: normal;
    }

    .fa {
        font-size: 1rem;
    }

    .im .fa {
        width: 1.5rem;
    }

    .fa-external-link {
        color: color('grey', 'lighten-1');
    }
}
