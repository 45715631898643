html,
body,
.full-height {
    height: 100%;
}

body {
    background-color: $background-color;

    &.locked {
        overflow-y: hidden;
    }
}

// This is needed to hide horizontal scrollbars on Windows machines :-(
.hero {
    overflow: hidden;
}

// Typography improvements
h1,
h2,
h3,
.brand-logo,
.button-collapse {
    -webkit-font-smoothing: antialiased;
    font-kerning: normal;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-feature-settings: 'liga', 'kern';
    text-rendering: optimizeLegibility;
}

h2 {
    margin-bottom: 0;
    font-size: 6rem;
    font-weight: 200;
    letter-spacing: -2px;
    text-align: center;

    @media #{$small-and-down} {
        font-size: 3rem;
    }

    span {
        position: relative;
        z-index: 10;
        padding: 0 1rem;
        background: $background-color;
    }

    &::after {
        position: relative;
        display: block;
        top: -4rem;
        width: 100%;
        height: 2px;
        margin: 1rem auto 0;
        background-color: transparentize($grey, 0.8);
        content: '';

        @media #{$small-and-down} {
            top: -3rem;
        }
    }

    + h3 {
        margin-top: -0.75rem;
        margin-bottom: 3rem;
    }
}

h3 {
    text-align: center;

    @media #{$small-and-down} {
        font-size: 1.8rem;
    }
}

section {
    padding-bottom: 5rem;

    &.hero {
        padding-bottom: 0;
    }

    @media #{$small-and-down} {
        padding-bottom: 3rem;
    }
}

.nobr {
    white-space: nowrap;
}

.collapsible {
    box-shadow: none;
}

$container-width: 90%;

.container {
    width: $container-width;
    max-width: 1600px;

    @media #{$medium-and-up} {
        width: $container-width;
    }

    @media #{$large-and-up} {
        width: $container-width;
    }

    &.full-width {
        width: 100%;
    }

    &.row.narrow-row {
        margin-right: 0;
        margin-left: 0;
    }
}

.row .col.padding-bottom {
    padding-bottom: 1.5rem;
}

.extra-padding {
    padding: 3rem 0;

    @media #{$small-and-down} {
        padding: 1.5rem 0;
    }
}

.no-padding {
    padding: 0;
}

.margin-top {
    margin-top: 3rem;
}

.margin-top-more {
    margin-top: 6rem;

    @media #{$small-and-down} {
        margin-top: 3rem;
    }
}

.big-icons-row i {
    font-size: 6rem;

    @media #{$small-and-down} {
        font-size: 4rem;
    }
}

iframe {
    border: 0;
}

.progress {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: none;
    margin: 0;
    background-color: $light-grey;

    .indeterminate {
        background-color: $grey;
    }
}

.width--pct--5 {
    width: 5% !important;
}

.width--pct--10 {
    width: 10% !important;
}

.width--pct--15 {
    width: 15% !important;
}

.width--pct--20 {
    width: 20% !important;
}

.width--pct--25 {
    width: 25% !important;
}

.width--pct--30 {
    width: 30% !important;
}

.width--pct--35 {
    width: 35% !important;
}

.width--pct--40 {
    width: 40% !important;
}

.width--pct--45 {
    width: 45% !important;
}

.width--pct--50 {
    width: 50% !important;
}
.width--pct--60 {
    width: 60% !important;
}
.width--pct--70 {
    width: 70% !important;
}
.width--pct--75 {
    width: 75% !important;
}
.width--pct--80 {
    width: 80% !important;
}
.width--pct--90 {
    width: 90% !important;
}
.width--pct--100 {
    width: 100% !important;
}

.pad--r--10 {
    padding-right: 10px !important;
}

.pad--b--40 {
    padding-bottom: 40px !important;
}
