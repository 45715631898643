// Colors
$button-background: color('purple', 'base');
$button-color: $white;
$h1-shadow-color: rgba(0, 0, 0, 0.2);
$primary-1: #3178ba;

.flex__container {
    display: flex;
}

.flex--jc--l {
    justify-content: flex-start;
}
.flex--jc--r {
    justify-content: flex-end;
}
.flex--jc--c {
    justify-content: center;
}
.flex--jc--sb {
    justify-content: space-between;
}

.flex--ai--t {
    align-items: flex-start;
}
.flex--ai--b {
    align-items: flex-end;
}
.flex--ai--c {
    align-items: center;
}

.flex--dir--col {
    flex-direction: column;
}

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    height: auto;
    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

// Skin customisation
.popkin-skin {
    .navbar-fixed {
        height: 0;
    }
    nav {
        .brand-logo {
            padding-top: 5px;
            img {
                height: 82px;
            }
        }
    }
    footer.page-footer {
        margin: 0px !important;
        padding: 0px !important;
    }
    .btn,
    .btn-large {
        color: $button-color;
        background-color: $button-background;
    }

    #video-viewport {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: -1;
    }

    video {
        display: block;
        width: 100%;
        height: auto;
    }

    .hero {
        height: 100%;
        overflow: hidden;
        display: block;

        &.fullsize-video-bg {
            overflow: hidden;
            position: relative;
            max-height: 560px;
            @media #{$medium-and-down} {
                max-height: 440px;
            }
            @media #{$small-and-down} {
                max-height: 420px;
            }
            &:before {
                content: '';
                background: rgba(114, 45, 0, 0.35);
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 0;
            }
            &:after {
                content: '';
                //background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgTWFjaW50b3NoIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjI4RkZBQTgzNzg1NzExRTU4NTQyODc3OUM4MTZGMUREIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjI4RkZBQTg0Nzg1NzExRTU4NTQyODc3OUM4MTZGMUREIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MjhGRkFBODE3ODU3MTFFNTg1NDI4Nzc5QzgxNkYxREQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MjhGRkFBODI3ODU3MTFFNTg1NDI4Nzc5QzgxNkYxREQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz66uHInAAAAIUlEQVR42mL5//8/AyMj42YGIGBigABfEMEIkoEBgAADAKvuBwVS8BAjAAAAAElFTkSuQmCC);
                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHklEQVQYV2NkYGD4z8DAwIhMgzgggCKBogKmg3iVAJgZDQNmrIUhAAAAAElFTkSuQmCC);
                background-size: 3px 3px;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                overflow: hidden;
            }

            .overlay {
                z-index: 2;
                display: flex;
                width: 100%;
                height: 100%;
                position: relative;
                .overlay--content {
                    display: flex;
                    align-items: center;
                    // width: 50%;
                    justify-content: center;
                    #quote__rotator {
                        max-width: 75%;
                        display: flex;
                        justify-content: center;
                        .hero--logo {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            img {
                                width: 125px;
                                height: 125px;
                            }
                        }
                        .quote {
                            font-size: 36px;
                            line-height: 1em;
                            color: white;
                            text-transform: uppercase;
                            font-weight: 500;
                            text-shadow: 2px 3px black;
                            text-align: center;
                            @media #{$medium-and-down} {
                                font-size: 24px;
                            }
                        }
                    }
                }
                .overlay--person {
                    display: flex;
                    align-items: flex-end;
                    //width: 50%;
                    justify-content: center;
                    @media #{$small-and-down} {
                        justify-content: flex-end;
                        img {
                            // scale: 0.8;
                        }
                    }
                }
            }
        }

        h1 {
            @include transform(translateY(-50%));
            position: absolute;
            top: 50%;
            z-index: 10;
            width: 100%;
            padding: 0 2rem;
            margin: 0;
            font-size: 5rem;
            font-weight: 700;
            letter-spacing: 2px;
            color: $white;
            text-align: center;
            text-shadow: 0 1px 1px $h1-shadow-color;
            text-transform: uppercase;

            @media #{$small-and-down} {
                padding: 0 1rem;
                font-size: 3rem;
            }
        }
    }

    #bio {
        #bio__wrapper {
            @media #{$small-and-down} {
                display: flex;
                flex-direction: column-reverse;
            }
        }
    }

    #didYouKnow {
        .row {
            .dyk {
                margin-bottom: 40px;
                height: auto;
                font-size: 18px;
                box-sizing: border-box;
                line-height: 24px;
                span {
                    font-size: 60px;
                    font-weight: bold;
                    display: flex;
                    margin: 12px;
                    color: $primary-1;
                }
            }
        }
    }
}
