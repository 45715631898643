.projects h2 {
    margin-top: 5rem;
}

.projects .content {
    height: 100%;

    div {
        @include transform(translate3d(0, 100%, 0));
        @include transition(all 0s linear);
        padding: 0 1rem;
        font-size: 4rem;
        line-height: 4rem;
        color: $white;
        opacity: 0;

        @media #{$small-and-down} {
            @include transform(translate3d(0, 0, 0));
            font-size: 2rem;
            line-height: 2rem;
            text-shadow: 0 1px 1px transparentize($black, .75);
            opacity: 1;
        }
    }

    @media #{$small-and-down} {
        background: transparentize($black, .9);
    }

    &:hover {
        background-color: transparentize($black, .3);

        div {
            @include transform(translate3d(0, 0, 0));
            @include transition(all .3s $ease-out-circ);
            opacity: 1;
        }

        @media #{$small-and-down} {
            @include transition(none);
            background-color: transparent;
        }
    }
}
