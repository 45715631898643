footer.page-footer {
    color: $black;
    background-color: $light-grey;

    .footer-copyright {
        color: $black;
    }

    input[type=submit]:focus {
        outline: 0;
    }
}
