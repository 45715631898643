.table-of-contents li {
    padding: 0;

    .active,
    a,
    a:hover {
        padding: 0;
        margin: 0 20px;
        font-weight: normal;
        border-left: 0;
    }

    a {
        border-bottom: 1px solid color($nav-item-border-color, 'lighten-2');
    }

    a:hover {
        border-bottom: 1px solid color($nav-item-border-color, 'base');
    }

    .active {
        border-bottom: 1px solid color($nav-item-border-color, 'darken-1');
    }

    &:last-of-type a {
        margin-right: 0;
    }
}

nav {
    background-color: $white;
    box-shadow: none;

    .brand-logo {
        font-weight: 500;
        letter-spacing: -1px;
        color: $black;
    }

    ul a,
    a {
        color: $black;

        &:hover {
            background: none;
        }
    }

    .table-of-contents a,
    .table-of-contents a:hover {
        color: $black;
    }
}

.hamburger {
    position: relative;
    width: 20px;
    height: 2px;
    cursor: pointer;
    background: $black;

    &::after,
    &::before {
        position: relative;
        display: block;
        width: 20px;
        height: 2px;
        background: $black;
        content: '';
    }

    &::after {
        top: -8px;
    }

    &::before {
        top: 6px;
    }
}
