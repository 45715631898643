// Using Bourbon for mixins and stuff: http://bourbon.io/docs/
@import '../../bower_components/bourbon/app/assets/stylesheets/bourbon';

/*
 * Table of contents:
 * 01 Common elements
 * 02 Spinners
 * 03 Navigation
 * 04 Layout blocks
 * 05 Modals
 * 06 Toasts
 * 07 Social
 * 08 Projects
 * 09 Owl gallery
 * 10 Services
 * 11 Prices
 * 12 About
 * 13 Blog
 * 14 Contact
 * 15 Inputs
 * 16 Footer
 * 17 Skins: Ring green
 * 18 Skins: Ring pink
 * 19 Skins: Tunnel
 * 20 Skins: Waves horizontal
 * 21 Skins: Waves vertical
 * 22 Skins: Fuzzy hue
 * 23 Skins: Fuzzy saturation
 * 24 Skins: Combustion purple
 * 25 Skins: Combustion yellow
 */

// Not compiled
@import 'materialize-color';

// Not compiled
@import 'media-query-ranges';

// Not compiled
@import 'constant-colors';

/********************************************/
/*
/* 01 Common elements
/*
/********************************************/
@import 'common';

/********************************************/
/*
/* 02 Spinners
/*
/********************************************/
@import 'spinners';

/********************************************/
/*
/* 03 Navigation
/*
/********************************************/
@import 'nav';

/********************************************/
/*
/* 04 Layout blocks
/*
/********************************************/
@import 'blocks';

/********************************************/
/*
/* 05 Modals
/*
/********************************************/
@import 'modal';

/********************************************/
/*
/* 06 Toasts
/*
/********************************************/
@import 'toasts';

/********************************************/
/*
/* 07 Social
/*
/********************************************/
@import 'social';

/********************************************/
/*
/* 08 Projects
/*
/********************************************/
@import 'projects';

/********************************************/
/*
/* 09 Owl gallery
/*
/********************************************/
@import 'owl';

/********************************************/
/*
/* 10 Services
/*
/********************************************/
@import 'services';

/********************************************/
/*
/* 11 Prices
/*
/********************************************/
@import 'prices';

/********************************************/
/*
/* 12 About
/*
/********************************************/
@import 'about';

/********************************************/
/*
/* 13 Blog
/*
/********************************************/
@import 'blog';

/********************************************/
/*
/* 14 Contact
/*
/********************************************/
@import 'contact';

/********************************************/
/*
/* 15 Inputs
/*
/********************************************/
@import 'inputs';

/********************************************/
/*
/* 16 Footer
/*
/********************************************/
@import 'footer';

@import 'skins/popkin';
