.prices {
    .divider {
        width: 50%;
        height: 2px;
        margin: 1.5rem auto 0;
    }

    strong + p {
        margin-top: 0;
    }

    .info {
        position: relative;
        top: -3px;
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        margin-left: .5rem;
        font-size: 1rem;
        line-height: 1.5rem;
        color: color('grey', 'base');
        cursor: help;
        background: color('grey', 'lighten-2');
        border-radius: 50%;
    }

    h5 {
        font-size: 4rem;
        font-weight: 200;
    }

    .currency {
        position: relative;
        top: -1.5rem;
        font-size: 1.5rem;
        font-weight: 300;
    }
}
