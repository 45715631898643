@mixin social-link($network, $color) {
    &.#{$network} {
        color: $white;
        background-color: $color;

        &:hover {
            background-color: transparentize($color, .3);
        }
    }
}

.social {
    .content {
        @include justify-content(center);
        height: 100%;
    }

    a {
        @include social-link(facebook, $facebook);
        @include social-link(twitter, $twitter);
        @include social-link(dribbble, $dribbble);
        @include social-link(github, $github);
        @include social-link(vk, $vk);
        @include social-link(linkedin, $linkedin);
        @include social-link(googleplus, $googleplus);
        @include social-link(pinterest, $pinterest);

        display: inline-block;
        padding: 3px 5px;
        margin: 0 3px;

        @media #{$small-and-down} {
            margin-bottom: 5px;
        }

        &:first-of-type {
            margin-left: 5px;
        }
    }

    &.huge a {
        margin: 0;
    }
}
