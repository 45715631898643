$inactive: color('grey', 'base');
$active: $white;
$speed: 2s;
$size: 60px;
$unit: $size / 16;

%spinner {
    display: block;
    float: left;
    width: $size;
    height: $size;
    border: $unit solid $inactive;
    border-radius: 50%;
    animation: spinner $speed linear infinite;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

// Dots
%spinner-double-dot-in,
.spinner-double-dot-in {
    @extend %spinner;
    position: relative;

    &::before,
    &::after {
        position: absolute;
        top: ($unit * 2);
        left: ($size / 2 - $unit);
        width: ($unit * 2);
        height: ($unit * 2);
        background: $active;
        border-radius: 50%;
        content: '';
    }

    &::after {
        top: auto;
        bottom: ($unit * 2);
    }
}

%spinner-dot-in,
.spinner-dot-in {
    @extend %spinner-double-dot-in;

    &::after {
        display: none;
    }
}

%spinner-double-dot-stick,
.spinner-double-dot-stick {
    @extend %spinner-double-dot-in;

    &::before,
    &::after {
        top: (-$unit * 2);
    }

    &::after {
        top: auto;
        bottom: (-$unit * 2);
    }
}

%spinner-dot-stick,
.spinner-dot-stick {
    @extend %spinner-double-dot-stick;

    &::after {
        display: none;
    }
}

%spinner-double-dot-out,
.spinner-double-dot-out {
    @extend %spinner-double-dot-in;

    &::before,
    &::after {
        top: (-$unit * 4);
    }

    &::after {
        top: auto;
        bottom: (-$unit * 4);
    }
}

%spinner-dot-out,
.spinner-dot-out {
    @extend %spinner-double-dot-out;

    &::after {
        display: none;
    }
}

// Waves
%spinner-wave-out,
.spinner-wave-out {
    @extend %spinner;
    box-shadow: (-$unit / 2) (-$unit / 2) 0 ($unit / 2) $active;
}

%spinner-wave-in,
.spinner-wave-in {
    @extend %spinner;
    box-shadow: inset (-$unit / 2) (-$unit / 2) 0 ($unit / 2) $active;
}

%spinner-double-wave-out,
.spinner-double-wave-out {
    @extend %spinner;
    box-shadow: (-$unit / 2) (-$unit / 2) 0 ($unit / 2) $active,
            ($unit / 2) ($unit / 2) 0 ($unit / 2) $active;
}

%spinner-double-wave-in,
.spinner-double-wave-in {
    @extend %spinner;
    box-shadow: inset (-$unit / 2) (-$unit / 2) 0 ($unit / 2) $active,
    inset ($unit / 2) ($unit / 2) 0 ($unit / 2) $active;
}

%spinner-huge-wave-out,
.spinner-huge-wave-out {
    @extend %spinner;
    box-shadow: (-$unit / 2) (-$unit / 2) 0 ($unit * 2) $active;
}

%spinner-huge-wave-in,
.spinner-huge-wave-in {
    @extend %spinner;
    box-shadow: inset (-$unit / 2) (-$unit / 2) 0 ($unit * 2) $active;
}

// Sections
%spinner-double-section,
.spinner-double-section {
    @extend %spinner;
    position: relative;

    &::before,
    &::after {
        position: absolute;
        top: -$unit;
        left: -$unit;
        display: block;
        width: $size;
        height: $size;
        border: $unit solid transparent;
        border-top-color: $active;
        border-radius: 50%;
        content: '';
    }

    &::after {
        border-top-color: transparent;
        border-bottom-color: $active;
    }
}

%spinner-section,
.spinner-section {
    @extend %spinner-double-section;

    &::after {
        display: none;
    }
}

%spinner-double-section-in,
.spinner-double-section-in {
    @extend %spinner-double-section;

    &::before,
    &::after {
        top: 0;
        left: 0;
        width: ($size - $unit * 2);
        height: ($size - $unit * 2);
    }
}

%spinner-section-in,
.spinner-section-in {
    @extend %spinner-double-section-in;

    &::after {
        display: none;
    }
}

%spinner-double-section-out,
.spinner-double-section-out {
    @extend %spinner-double-section;

    &::before,
    &::after {
        top: ($unit * - 2);
        left: ($unit * - 2);
        width: ($size + $unit * 2);
        height: ($size + $unit * 2);
    }
}

%spinner-section-out,
.spinner-section-out {
    @extend %spinner-double-section-out;

    &::after {
        display: none;
    }
}

%spinner-double-section-far,
.spinner-double-section-far {
    @extend %spinner-double-section;

    &::before,
    &::after {
        top: ($unit * - 3);
        left: ($unit * - 3);
        width: ($size + $unit * 4);
        height: ($size + $unit * 4);
    }
}

%spinner-section-far,
.spinner-section-far {
    @extend %spinner-double-section-far;

    &::after {
        display: none;
    }
}

.spinner-container {
    @include justify-content(center);
    @include transition(all .4s $ease-out-circ);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: $black;

    > div {
        @include transition(opacity .3s $ease-out-circ);
        opacity: 1;
    }

    &.hide-spinner {
        top: 50%;
        height: 0;

        > div {
            opacity: 0;
        }
    }
}
