.modal {
    &.bottom-sheet {
        height: 100%;
        max-height: none;
    }

    blockquote {
        font-size: 3rem;
        font-weight: 200;
        border-left: 0;

        @media #{$small-and-down} {
            font-size: 1.5rem;
            font-weight: 300;
        }
    }
}

.modal-close {
    position: absolute;
    top: 0;
    left: 1.5rem;
    z-index: 10000;
    font-size: 3rem;

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 1rem;
        content: 'esc';
    }
}

.about .modal.bottom-sheet {
    height: auto;

    @media #{$medium-and-down} {
        height: 100%;

        .modal-content .square {
            padding-bottom: 0;

            .content {
                position: relative;
            }
        }
    }
}
